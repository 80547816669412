/* eslint-disable */
<template>
  <v-card variant="text" class="mt-4 mx-auto">
    <v-form v-model="form" @submit.prevent="next">
      <p class="text-center text-h5 mb-5">{{ $t('app.Labels.PersonalInformation').toUpperCase() }}</p>
      <v-select v-model="visitante.tipo_documento_visitante_id" :label="$t('app.Fields.TypeIDDocument')"
        :rules="[v => !!v || $t('app.Errors.TypeIDDocumentValidation')]" :items="itemsIdioma" item-value="id"
        :item-title="item => item.descripcion.toUpperCase()"></v-select>
      <v-text-field v-model="visitante.documento_identidad" :type="isDni ? 'number' : 'text'" :rules="documentoRules"
        :label="$t('app.Fields.IDDN')" :maxlength="isDni ? 8 : null" class="flex-grow-1" @keyup.enter="verificarDni"
        :hint="$t('app.Hints.PressEnter')">
      </v-text-field>
      <v-text-field v-model="visitante.nombres" :rules="[v => !!v || $t('app.Errors.NameValidation')]"
        :label="$t('app.Fields.Name')"></v-text-field>
      <v-text-field v-model="visitante.apellido_paterno" :rules="[v => !!v || $t('app.Errors.LastNameValidation')]"
        :label="$t('app.Fields.LastName')"></v-text-field>
      <v-text-field v-model="visitante.apellido_materno" :label="$t('app.Fields.SecondLastName')"></v-text-field>
      <v-text-field v-model="visitante.fecha_nacimiento" type="date"
        :rules="[v => !!v || $t('app.Errors.BirthDateValidation')]" :label="$t('app.Fields.Birthdate')"></v-text-field>
      <!--       <v-text-field v-model="visitante.documento_identidad" :type="isDni ? 'number' : 'text'" :rules="documentoRules"
        :label="$t('app.Fields.IDDN')" :maxlength="isDni ? 8 : null"></v-text-field> -->
      <v-autocomplete v-model="visitante.empresa_visitante_id" :items="empresasOrdenadas"
        :item-title="item => item.descripcion.toUpperCase()" class="mr-2" item-value="id"
        :label="$t('app.Fields.Company')" clearable></v-autocomplete>

      <v-text-field v-if="isOtherCompany" v-model="visitante.empresa" :label="$t('app.Fields.Company')"
        :rules="[v => !!v || $t('app.Errors.CompanyValidation')]"></v-text-field>
      <v-text-field v-if="isSedeRequired" v-model="visitante.sitio" :label="$t('app.Fields.CompanyHeadquarter')"
        :rules="[v => !!v || $t('app.Errors.CompanyHeadquarterValidation')]"></v-text-field>
      <v-text-field v-model="visitante.email" :label="$t('app.Fields.Mail')" type="email"
        :rules="[v => !!v || $t('app.Errors.EmailValidation'), v => /.+@.+\..+/.test(v) || $t('app.Errors.EmailValidationFormat')]"></v-text-field>
      <v-text-field v-model="visitante.actividad_a_realizar" :label="$t('app.Fields.Activity')"
        :rules="[v => !!v || $t('app.Errors.ActivityValidation')]"></v-text-field>
      <div class="d-flex">
        <v-autocomplete :items="arrayCodigos" :item-title="item => item.name + ' ' + item.dial_code + ' ' + item.emoji"
          class="mr-2" item-value="dial_code" label="Codigo Pais" clearable
          v-model="visitante.codigo_pais"></v-autocomplete>
        <v-text-field v-model="visitante.telefono_contacto" :label="$t('app.Fields.Phone')"
          :rules="[v => !!v || $t('app.Errors.PhoneValidation')]"></v-text-field>
      </div>
      <v-dialog v-model="mostrarModal" persistent max-width="600">
        <v-card>
          <v-card-title class="text-h6">
            {{ $t('app.Modals.VisitExamTitle') }}
          </v-card-title>
          <v-card-text>
            {{ modalMessage }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" @click="seguirCompletando">{{ $t('app.Modals.ContinueFilling') }}</v-btn>
            <v-btn color="primary" @click="usarFormatoAnterior">{{ $t('app.Modals.UsePreviousFormat') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card-actions>
        <v-btn class="mt-2" variant="flat" color="orange-darken-1" @click="atras">{{ $t('app.Buttons.Back') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mt-2" variant="flat" color="success" type="submit">{{ $t('app.Buttons.Next') }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      form: false,
      mostrarModal: false,
      modalMessage: '',
      items_en: [
        { id: 1, descripcion: "DNI" },
        { id: 2, descripcion: "FOREIGNER CARD" },
        { id: 3, descripcion: "PASSPORT" }
      ],
      items_es: [
        { id: 1, descripcion: "DNI" },
        { id: 2, descripcion: "CARNET DE EXTRANJERIA" },
        { id: 3, descripcion: "PASAPORTE" }
      ]
    };
  },
  computed: {
    visitante: {
      get() {
        return this.$store.state.visitante;
      },
      set(newValue) {
        this.$store.commit('SET_VISITANTE', newValue);
      }
    },
    arrayCodigos() {
      return this.$store.state.dialcode.codes;
    },
    empresas() {
      return this.$store.state.empresas;
    },
    itemsIdioma() {
      return this.$i18n.locale === 'es' ? this.items_es : this.items_en;
    },
    isOtherCompany() {
      return this.visitante.empresa_visitante_id == '2';
    },
    isSedeRequired() {
      return this.visitante.empresa_visitante_id == '1';
    },
    isDni() {
      return this.visitante.tipo_documento_visitante_id === 1;
    },
    documentoRules() {
      const rules = [v => !!v || this.$t('app.Errors.IDDNValidation')];
      if (this.isDni) {
        rules.push(v => v.length === 8 || this.$t('app.Errors.DNIValidation'));
      }
      return rules;
    },
    empresasOrdenadas() {
      // Ordena manualmente las primeras tres empresas
      let ordenPersonalizado = ['FREEPORT MCMORAN', 'SOCIEDAD MINERA CERRO VERDE S.A.A.', 'OTROS'];

      // Filtra y reordena las primeras empresas
      let primerasEmpresas = this.empresas.filter(e =>
        ordenPersonalizado.includes(e.descripcion.toUpperCase())
      ).sort((a, b) => ordenPersonalizado.indexOf(a.descripcion.toUpperCase()) - ordenPersonalizado.indexOf(b.descripcion.toUpperCase()));

      // El resto de empresas que no están en el orden personalizado
      let restoEmpresas = this.empresas.filter(e =>
        !ordenPersonalizado.includes(e.descripcion.toUpperCase())
      );

      // Retorna el array final con las primeras empresas ordenadas y luego el resto
      return [...primerasEmpresas, ...restoEmpresas];
    }
  },
  methods: {
    next() {
      if (this.form) {
        this.$store.dispatch('guardarDatosPersonales', this.visitante);
      }
    },
    atras() {
      this.$store.commit('SET_VENTANA', 1);
    },
    setDefaultCountryCode() {
      this.visitante.codigo_pais = this.$i18n.locale === 'es' ? '+51' : '+1';
    },
    verificarDni() {
      this.$store.dispatch('verificarVigencia', this.visitante.documento_identidad)
        .then(response => {
          if (response.status === 'success') {
            // Mostrar el mensaje con la fecha de vigencia
            this.modalMessage = this.$t('app.Modals.modalMessage', { vigencia: response.vigencia });
            this.mostrarModal = true;
          } else {
            this.$toast.error(response.message);
          }
        })
        .catch(() => {
          this.$toast.error('Error al verificar el DNI.');
        });
    },
    seguirCompletando() {
      this.mostrarModal = false;
    },
    usarFormatoAnterior() {
      const dni = this.visitante.documento_identidad;

      this.$store.dispatch('crearRegistroVigente', dni)
        .then(response => {
          if (response.status === 'success') {
            this.$toast.success('Registro vigente creado con éxito.');
          } else {
            this.$toast.error(response.message);
          }
        })
        .catch(() => {
          this.$toast.error('Error al crear el registro vigente.');
        });

      this.mostrarModal = false;
    }
  },
  watch: {
    '$i18n.locale': 'setDefaultCountryCode'
  },
  mounted() {
    this.setDefaultCountryCode();
  }
}
</script>
