export default {
  "app": {
    "AppBar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formato de visita"])},
    "LanguajeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["idioma"])},
    "Labels": {
      "PersonalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información Personal"])},
      "MedInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información Medica"])},
      "MedicalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El (la) paciente ha presentado en los últimos 6 meses lo siguiente"])},
      "CurrentMedication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso de medicación actual"])},
      "Attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntar Documentos"])},
      "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
      "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "DataSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacion guardada Correctamente"])},
      "DataWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al Guardar Formulario"])},
      "MessageDataSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos seran enviados al personal medico de International SOS"])},
      "MessageDataWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor volver a llenar el formulario"])},
      "DataAuthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización de uso de Información"])},
      "DataConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizo que mis datos personales sean tratados en el cuestionario médico que estoy completando por International SOS a solicitud de Sociedad Minera Cerro Verde S.A.A. He sido debidamente informado acerca de que mis datos se utilizarán con fines estrictamente médicos y se mantendrán confidenciales. Declaro que mi participación es voluntaria y puedo ejercer mis derechos de acceso, restricción, cancelación y oposición en cualquier momento, enviando una solicitud a DL.SUP.ASIS", "@", "internationalsos.com o SM-CVE-datospersonales", "@", "fmi.com. Para mayor información sobre el tratamiento, podrá encontrar la política de privacidad en este  "])},
      "AtttachDocumentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificamos que selecciono una condición que amerita una evaluación por el área médica de Sermedi, para lo cual requerimos pueda adjuntar el informe médico actualizado o evidencia de tratamiento médico (puede ser una foto)."])},
      "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enlace"])},
      "SwornStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La información declarada en este cuestionario será considerada como declaración jurada."])},
      "BMI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indice de Masa Corporal"])},
      "BMIMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin observación relacionado con obesidad."])},
      "BMIMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar obligatoriamente informes médicos para revisión por el Área Médica:"])},
      "BMIMessage21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Informe de Cardiología y Prueba de esfuerzo , con 3 meses de antiguedad."])},
      "BMIMessage22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Informe de Endocrinología y hemoglobina glicosilada, con 3 meses de antiguedad"])},
      "BMIMessage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede ingresar a las instalaciones de Sociedad Minera Cerro Verde."])},
      "NoCoca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaro que no consumo coca, hoja de coca y sus derivados."])},
      "NoMarijuana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaro que no consumo marihuana, aceite de cannabis y/o cualquier otro de sus derivados."])},
      "PresentationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
      "PresentationBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a la Plataforma de International SOS. Para poder ingresar como visitante a las instalaciones de Sociedad Minera Cerro verde S.A.A. ubicadas en Arequipa – Peru usted deberá cumplir con ciertos requisitos de salud obligatorios los cuales se encuentran descritos en el Reglamento de Seguridad y Salud Ocupacional para Minería (D.S. 024-2016-EM y sus modificatorias)."])},
      "PresentationBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes de completar el presente formulario electrónico usted deberá leer detenidamente los requisitos indicados en el"])},
      "PresentationBody21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor tome el tiempo de analizar la información que se le podría solicitar dependiendo de su condición médica con la finalidad que al momento de completar el formulario tenga a la mano la documentación necesaria (Informes médicos especializados, recetas médicas, resultados de laboratorio, entre otros) que sustente que, en caso de presentar alguna patología que restrinja su visita, se encuentre debidamente controlada."])},
      "PresentationBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tome en cuenta que la información enviada será evaluada por parte de los médicos de Salud Ocupacional de International SOS y contrastada con los requisitos médicos mencionados por lo que tiene carácter de Declaración Jurada y su falsedad o alteración puede ser motivo de acciones legales y/o disciplinarias.Una vez completado el llenado del formulario, usted recibirá un correo electrónico a la casilla que ha proporcionado, indicando los pasos a seguir. Si luego de la evaluación médica se determina que usted se encuentra habilitado podrá coordinar con Travel Center corporativo para la emisión de los boletos aéreos."])},
      "PresentationBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota: la presente evaluación es un pre-requisito de ingreso a las instalaciones de Sociedad Minera Cerro Verde S.A.A. Una vez usted se encuentre en las instalaciones deberá pasar por una evaluación presencial adicional donde se corroborarán los datos enviados y se tomarán exámenes clínicos y toxicológicos para confirmar su aptitud para continuar la visita. En caso se encuentren valores alterados la visita no podrá continuar y deberá coordinar con su huésped las acciones correspondientes"])},
      "PresentationBody5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por su Colaboración."])},
      "ClickToViewPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga click para ver el Archivo"])}
    },
    "Fields": {
      "FullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos y Nombres"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombres"])},
      "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido Paterno"])},
      "SecondLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido Materno"])},
      "Birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Nacimiento"])},
      "TypeIDDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir tipo de Documento"])},
      "IDDN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero de Documento de Identidad"])},
      "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir Tipo de Empresa"])},
      "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad a Realizar"])},
      "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electronico"])},
      "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto en caso de Emergencia"])},
      "CountryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codigo de Pais"])},
      "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono de Contacto"])},
      "RecentSurgery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cirugia Mayor Reciente"])},
      "Disorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desórdenes de la coagulación, trombosis, otros"])},
      "Diabetes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diabetes Mellitus"])},
      "Hypertension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hipertensión arterial"])},
      "Pregnancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embarazo"])},
      "NeurologicalDisorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas neurológicos: epilepsia, vértigos, otros"])},
      "RecentInfections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infecciones recientes (de moderadas a severas)"])},
      "Obesity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obesidad"])},
      "CardiacProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas cardiacos: marcapasos, coronariopatía, otros"])},
      "RespiratoryDisorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas respiratorios: asma, EPOC, otros"])},
      "OphthalmologicalProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas oftalmológicos: retinopatía, glaucoma, otros"])},
      "DigestiveProblems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas digestivos: sangrado digestivo, hepatitis, cirrosis, otros"])},
      "SleepApnea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apnea del sueño"])},
      "Allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergias"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "Tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talla"])},
      "BMI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMC"])},
      "SelectUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione Sistema Metrico"])},
      "RecentSurgerySub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeza, cuello, tórax o alguna cirugía abdominal en los últimos 3 meses."])},
      "DisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteración en la coagulación, la consecuencia puede ser aumento de la formación de coágulos o sangrado de difícil control."])},
      "DiabetesSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfermedad en la que resulta difícil controlar el nivel de glucosa en sangre. Sin control puede afectar órganos importantes como: corazón, cerebro, riñones, ojos, entre otros."])},
      "HypertensionSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El corazón trabaja más porque encuentra vasos sanguíneos rígidos y los órganos reciben menos oxígeno. Puede haber rotura de un vaso sanguíneo cerebral, daño renal, etc."])},
      "PregnancySub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período en el que se desarrolla un feto dentro del útero de la mujer."])},
      "NeurologicalDisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epilepsia, vértigo, otros: Vértigo: Es la sensación de inestabilidad al caminar, que desaparece al sentarse o acostarse."])},
      "RecentInfectionsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada, desarrollo y multiplicación de un agente infeccioso en el organismo de una persona."])},
      "CardiacProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcapasos, enfermedad de las arterias coronarias, otras: Se refiere a que la persona tiene antecedentes de enfermedades del corazón, por ejemplo: uso de marcapasos cardíaco, patología de las arterias coronarias (enfermedad de las arterias coronarias)."])},
      "RespiratoryDisordersSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asma, EPOC, otros: Esto significa que la persona tiene antecedentes de enfermedades respiratorias crónicas, por ejemplo, asma, enfermedad pulmonar obstructiva crónica (EPOC)."])},
      "OphthalmologicalProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retinopatía, glaucoma, otros: Se refiere a enfermedades oculares crónicas, alteración de los vasos sanguíneos, nervios u otras estructuras del ojo que pueden provocar ceguera."])},
      "DigestiveProblemsSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sangrado digestivo, hepatitis, cirrosis, otros: Se refiere a enfermedades del sistema digestivo crónico (cirrosis hepática), úlceras esofágicas o varices pueden provocar sangrado digestivo, por ejemplo."])},
      "SleepApneaSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se refiere a problemas para respirar mientras se duerme. Esta enfermedad puede provocar somnolencia y fatiga si no se controla"])},
      "AllergiesSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es importante detallar cualquier alergia, ya sea a alimentos o medicamentos, para llevar un registro de esta y avisar si ocurre algún evento relacionado durante su visita."])},
      "AnotherMedicalCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otra condición médica importante:"])},
      "AnotherMedicalConditionSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No indicadas en la relación anterior"])},
      "RecentSurgeryFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Cirugía indicando fecha y procedimiento quirúrgico realizado, actualizado con fecha no mayor a 3 meses desde el procedimiento."])},
      "RecentSurgeryFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exámenes de Laboratorio: hemograma con hematocrito, con fecha no mayor a 3 meses."])},
      "RecentSurgeryFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta actualizada en caso de continuar tomando medicación."])},
      "DisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar informe de Hematología o Medicina Interna o Cirugía Vascular o Neurocirugía, actualizado con fecha no mayor a 3 meses."])},
      "DisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pruebas de Laboratorio: pruebas de coagulación, con fecha no mayor a 3 meses."])},
      "DisordersFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "DiabetesFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Endocrinología, con 3 meses de antiguedad."])},
      "DiabetesFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exámenes de Laboratorio: Examen de glucemia y hemoglobina glucosilada (HbA1c), con fecha no mayor a 3 meses."])},
      "DiabetesFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "HypertensionFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Cardiología, con 3 meses de antiguedad."])},
      "HypertensionFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "PregnancyFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Obstetricia, informando semanas de gestación, con fecha no mayor a 4 semanas, con indicación de que puede viajar a altura mayor a 2500 msnm."])},
      "NeurologicalDisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe del servicio de Neurología, con fecha no mayor a 3 meses."])},
      "NeurologicalDisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "RecentInfectionsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deberá adjuntar Informe actualizado del médico tratante, con fecha no mayor a 1 mes, indicando el tratamiento recibido."])},
      "RecentInfectionsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "CardiacProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Cardiología, con fecha no mayor a 1 mes, indicando la presencia o ausencia de marcapasos, y los procedimientos realizados."])},
      "CardiacProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Prueba de Esfuerzo, con fecha no mayor a 1 mes."])},
      "CardiacProblemsFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "RespiratoryDisordersFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Neumología, con fecha no mayor a 1 mes."])},
      "RespiratoryDisordersFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba de Espirometría con fecha no mayor a 1 mes."])},
      "RespiratoryDisordersFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "OphthalmologicalProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar informe de Oftalmología con fecha no mayor a 1 mes."])},
      "OphthalmologicalProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "DigestiveProblemsFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar informe médico digestivo actualizado con fecha no mayor a 1 mes."])},
      "DigestiveProblemsFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exámenes de Laboratorio: hemograma con hematocrito, con fecha no mayor a 1 mes."])},
      "DigestiveProblemsFiles3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receta con medicación actualizada."])},
      "SleepApneaFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe adjuntar Informe de Neumología, con fecha no mayor a 3 meses."])},
      "AllergyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique cual:"])},
      "OtherConditionsFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añada la receta de cualquier otro medicamento que este tomando."])},
      "IMCFiles1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Cardiología y Prueba de esfuerzo, con 3 meses de antiguedad."])},
      "IMCFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Endocrinología y hemoglobina glicosilada, con 3 meses de antiguedad."])},
      "CompanyHeadquarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio"])}
    },
    "Buttons": {
      "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enviar"])},
      "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siguiente"])},
      "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atras"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho"])}
    },
    "Errors": {
      "NameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Campo Nombre es requerido"])},
      "LastNameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Campo Apellido Paterno es requerido"])},
      "SecondLastNameValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Campo NApellido Materno es requerido"])},
      "BirthDateValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Fecha de Nacimiento es requerido"])},
      "TypeIDDocumentValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Tipo de Documento es requerido"])},
      "IDDNValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Documento de Identidad es requerido"])},
      "CompanyValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Empresa es requerido"])},
      "EmailValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Email es requerido"])},
      "EmailValidationFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Email no cumple con el formato requerido"])},
      "ActivityValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Acividad a Realizar es requerido"])},
      "ContactValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Contacto en caso de Emergencia es requerido"])},
      "PhoneValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Telefono de Contacto es requerido"])},
      "CountryCodeValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Codigo de Pais es requerido"])},
      "WeightValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Peso es requerido"])},
      "TallValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Talla es requerido"])},
      "AnotherMedicalConditionValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Otra Condición Medica es requerido"])},
      "AtttachDocumentValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo Adjuntar Archivo es requerido"])},
      "DeclarationRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de Declaracion es requerido"])},
      "BMIOutOfRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El imc no esta en el rango aceptable"])},
      "InvalidCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caracter Invalido"])},
      "DNIValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El DNI debe tener 8 caracteres"])},
      "SelectOptionValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe Seleccionar al menos una opción."])}
    },
    "Modals": {
      "VisitExamTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vigencia Formato de Visita"])},
      "ContinueFilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar informacion anterior"])},
      "UsePreviousFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
      "modalMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usted cuenta con un examen de visita vigente hasta ", _interpolate(_named("vigencia")), ". Solo si cambió su condición puede continuar completando la información.De lo contrario hacer click en finalizar"])}
    },
    "ToolTip": {
      "Theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar Modo Visualizacion"])}
    },
    "Hints": {
      "Tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por Ejemplo : 1.78"])},
      "Weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ejemplo : 78"])},
      "ObseityAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este valor ha sido marcado debido a los valores proporcionados de peso y talla"])},
      "PressEnter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presione Enter despues de digitar su Numero de Documento"])}
    }
  }
}